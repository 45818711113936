@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0b2b4c;
    transition: all 0.3s;

    &.hideBg {
        background-color: #0b2b4c;
    }

    &.lightBg {
        .headerRowLinks {
            a {
                color: #fff;
            }

            .linkActive {
                color: #3279ff;
            }
        }

        :global(.Dropdown-control) {
            color: #fff !important;
        }

        :global(.Dropdown-arrow) {
            border-color: #fff transparent transparent !important;
        }

        :global(.is-open .Dropdown-arrow) {
            border-color: transparent transparent #fff !important;
        }
    }

    @media (max-width: $md3+px) {
        height: 65px;
    }

    @media (max-width: $md6+px) {
        height: 64px;
    }

    &::before {
        content: "";
        background: #0b2b4c;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        transition: all 0.3s;
        z-index: -1;
    }

    &Active {
        height: 100%;

        &::before {
            opacity: 1;
            height: 100%;
        }

        .langs {
            z-index: 800;
        }

        path {
            fill: white;
        }
    }

    .mobileMenu {
        @media (min-width: 1221px) {
            display: none;
        }

        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;

        .aLink {
            display: block;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
            font-family: $montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;
        }

        .btn {
            justify-content: center;
            display: flex;
            margin-top: 40px;

            a {
                @media (min-width: 1221px) {
                    display: none;
                }

                display: block;
                padding: 7px 18px;
                border: 1px solid #745527;
                border-radius: 30px;
                font-family: $montserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                cursor: pointer;
                transition: all 0.3s;
                color: #745527;
                background: transparent;

                &:hover {
                    color: #fff;
                    background: #745527;
                }
            }
        }

        &Active {
            display: flex;
            transition: transform 0.5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }

    .headerRow {
        display: flex;
        justify-content: space-between;
        grid-template-columns: 55px 1fr auto;
        gap: 30px;
        align-items: center;
        padding: 12px 0;

        @media (max-width: $md2+px) {
            padding: 10px 0;
        }

        @media (max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
        }

        @media (max-width: $md6+px) {
            padding: 12px 0;
        }

        &Logo {
            img {
                height: 53px;

                @media (max-width: $md3+px) {
                    height: 45px;
                }

                @media (max-width: $md6+px) {
                    height: 40px;
                }
            }

            svg {
                width: 100%;

                path {
                    transition: all 0.3s;
                }
            }
        }

        &Links {
            @media (max-width: $md3+px) {
                display: none;
            }

            display: flex;
            gap: 45px;
            font-family: $montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;

            @media (max-width: $md1+px) {
                font-size: 18px;
            }

            @media (max-width: $md2+px) {
                font-size: 16px;
                line-height: 19px;
                gap: 40px;
            }

            .linkActive {
                color: #745527;
            }

            a {
                color: #000000;
                text-decoration: none;
            }
        }

        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;

            @media (max-width: $md3+px) {
                gap: 35px;
            }

            @media (max-width: $md6+px) {
                gap: 35px;
            }

            .login {
                @media (max-width: $md3+px) {
                    display: none;
                }

                padding: 7px 18px;
                background: transparent;
                border: 1px solid #745527;
                border-radius: 30px;
                font-family: $montserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                color: #745527;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    color: #fff;
                    background: #745527;
                }
            }

            .burger {
                display: none !important;

                @media (max-width: $md5+px) {
                    display: block !important;
                }

                cursor: pointer;
                display: flex;
                width: 32px;
                height: 14px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    border-radius: 10px;
                    transition: all 0.3s;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    border-radius: 10px;
                    transition: all 0.3s;
                }

                &Active {
                    &::before {
                        top: 6px;
                        transform: rotate(45deg);
                        // background: #FFF;
                    }

                    &::after {
                        bottom: 6px;
                        transform: rotate(-45deg);
                        // background: #FFF;
                    }
                }
            }
        }
    }
}

.headerRowRightLink {
    cursor: pointer;

    @media (max-width: $md3+px) {
        position: relative;
    }

    @media (max-width: $md5+px) {
        top: 18px;
        font-size: 18px !important;
    }
}

.headerRowRightLink {
    @media (max-width: $md5+px) {
        display: none;

        &.mob {
            display: block;
            margin-bottom: 30px;
        }
    }
}

// .burger {
//     @media (min-width: 1221px) {
//         display: none;
//     }
//     cursor: pointer;
//     display: flex;
//     position: relative;
//     width: 24px;
//     height: 20px;
//     transition: all 0.3s;
//     span {
//         transition: all 0.3s;
//         position: absolute;
//         width: 100%;
//         left: 0;
//         height: 2px;
//         background: #2a2b2b;
//         &:nth-child(1) {
//             top: 0;
//         }
//         &:nth-child(2) {
//             top: calc(50% - 1px);
//         }
//         &:nth-child(3) {
//             bottom: 0;
//         }
//     }
//     &Active {
//         span {
//             &:nth-child(1) {
//                 transform: rotate(405deg) translateY(13px);
//                 left: 7px;
//             }
//             &:nth-child(2) {
//                 opacity: 0;
//             }
//             &:nth-child(3) {
//                 transform: rotate(-405deg) translateY(-13px);
//                 left: 7px;
//             }
//         }
//     }
// }

.mobContentBurger {
    opacity: 0;
    transition: all 0.3s linear;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding-top: 100px;
    text-align: center;

    @media (max-width: $md5+px) {
        &.active {
            opacity: 1;
            pointer-events: visible;
        }
    }
}