@import "../../../styles/var";

.banner {
    position: relative;
    overflow: hidden;
}
.bannerBody {
}
.bannerImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.2);
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.bannerContent {
    position: relative;
    z-index: 4;
    max-width: 580px;
    padding: 215px 0 205px 0;
    @media (max-width: $md3+px) {
        max-width: 470px;
        padding: 150px 0 120px 0;
    }
    @media (max-width: 1050px) {
        padding: 120px 0 490px 0;
    }
    @media (max-width: 600px) {
        padding: 120px 0 60px 0;
    }
}
.bannerContentWrap {
    position: relative;
}
.bannerContentImg {
    width: 685px;
    height: 565px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(100px, 50px) scale(0.9);
    @media (max-width: $md5+px) {
        transform: translate(50px, 50px) scale(0.9);
    }
    @media (max-width: $md3+px) {
        width: 585px;
        height: 465px;
    }
    @media (max-width: 640px) {
        width: 535px;
        height: 415px;
    }
    @media (max-width: 600px) {
        position: relative;
        width: 100%;
        height: auto;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        object-position: right bottom;
    }
}
.bannerContentTitle {
    margin-bottom: 20px;
}
.bannerContentSubtitle {
    margin-bottom: 40px;
}
.bannerContentText {
    margin-bottom: 60px;
}
.bannerBtn {
}
