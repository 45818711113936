@import "../../../styles/var";

.faq {
}
.faqBody {
}
.faqContent {
    max-width: 872px;
    margin: 0 auto;
}
.faqContentTitle {
    background: linear-gradient(92deg, #184a6d 11.46%, #3d6986 116.47%) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    margin-bottom: 60px;
    text-align: center;
    @media (max-width: $md4+px) {
        margin-bottom: 40px;
    }
    @media (max-width: $md6+px) {
        max-width: 200px;
        margin: 0 auto 30px auto;
    }
}
.faqItem {
    cursor: pointer;
}
.faqItemTitle {
    color: #184a6d !important;
    font-weight: 500 !important;
    padding-right: 70px;
    @media (max-width: $md6+px) {
        padding-right: 40px;
    }
}
.faqItemText {
    font-weight: 300 !important;
    color: #000 !important;
    display: none;
    margin-top: 20px;
    padding-right: 70px;
    @media (max-width: $md6+px) {
        padding-right: 40px;
    }
}
.faqItemPlus {
    position: absolute;
    right: 0;
    top: 9px;
    width: 35px;
    height: 35px;
    span {
        &:nth-child(1) {
            right: 0;
            top: 17px;
            position: absolute;
            background-color: #000;
            width: 15px;
            height: 1px;
            display: block;
        }
        &:nth-child(2) {
            right: 7px;
            top: 10px;
            position: absolute;
            background-color: #000;
            width: 1px;
            height: 15px;
            display: block;
        }
    }
}
.faqItem {
    position: relative;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    &.active {
        .faqItemText {
            display: block;
        }
        .faqItemPlus {
            span {
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }

    padding: 15px 0;
    border-bottom: 1px solid rgba(24, 74, 109, 0.5);
}
