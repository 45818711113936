@import '../../../styles/var';

.wrap {
    margin-top: 150px;

    @media (max-width: $md3+px) {
        margin-top: 130px;
    }

    @media (max-width: $md4+px) {
        margin-top: 120px;
    }

    @media (max-width: $md6+px) {
        margin-top: 110px;
    }

    .player {
        display: flex;
        margin: 0 auto;
        width: 100%;
        max-width: 400px;

        @media (max-width: $md1+px) {
            max-width: 360px;
        }

        @media (max-width: $md6+px) {
            max-width: 280px;
        }
    }
}