@import "../../../styles/var";

.button {
    padding: 14px 30px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    border-radius: 30px;
    color: #ffffff !important;
    transition: all 0.3s;
    font-weight: 400 !important;
    border-radius: 30px;
    border: 1px solid #fab27b;
    @media (max-width: $md2+px) {
        font-size: 16px;
        line-height: 19px;
    }

    &:hover {
        background: #fab27b;
        color: #0b2b4c !important;
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button1 {
    padding: 14px 30px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    border-radius: 30px;
    color: #184a6d !important;
    transition: all 0.3s;
    font-weight: 400 !important;
    border-radius: 30px;
    border: 1px solid #184a6d !important;
    @media (max-width: $md2+px) {
        font-size: 16px;
        line-height: 19px;
    }

    &:hover {
        background: #184a6d;
        color: #fab27b !important;
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
