@import "./_var.scss";

body,
html {
    background: #f7f7f7;
    color: rgba(0, 0, 0, 0.7);
    font-family: $montserrat;

    &.lock {
        overflow: hidden;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pt {
    padding-top: 180px;

    @media (max-width: $md1+px) {
        padding-top: 160px;
    }

    @media (max-width: $md4+px) {
        padding-top: 140px;
    }

    @media (max-width: $md6+px) {
        padding-top: 120px;
    }
}

.mt {
    margin-top: 160px;

    @media (max-width: $md1+px) {
        margin-top: 140px;
    }

    @media (max-width: $md4+px) {
        margin-top: 140px;
    }

    @media (max-width: $md6+px) {
        margin-top: 120px;
    }
}

.mb {
    margin-bottom: 160px;

    @media (max-width: $md1+px) {
        margin-bottom: 140px;
    }

    @media (max-width: $md4+px) {
        margin-bottom: 140px;
    }

    @media (max-width: $md4+px) {
        margin-bottom: 120px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.Dropdown-control {
    font-family: $montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding-right: 30px;
    font-size: 20px;

    @media (max-width: $md6+px) {
        font-size: 16px;
        margin-right: 0px !important;
        padding-right: 15px;

        span {
            right: 0px !important;
        }
    }

    cursor: pointer;

    @media (max-width: $md3+px) {
        position: relative;
    }

    @media (max-width: $md5+px) {
        top: 0;
    }

    &:hover {
        box-shadow: none;
    }
}

.Dropdown-menu {
    font-family: $montserrat;
    font-style: normal;
    position: absolute;
    z-index: 10;
    font-weight: 400;
    font-size: 16px;
    max-height: unset !important;

    @media (max-width: $md3+px) {
        margin-top: 16px !important;
    }

    @media (max-width: $md5+px) {
        margin-top: 0px !important;
    }
}

.Dropdown-arrow {
    border-color: #fff transparent transparent;
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #fff;
}

.font-120 {
    background: linear-gradient(92deg, #184a6d 11.46%, #3d6986 116.47%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $caveat;
    font-size: 120px;
    line-height: 1.2;

    @media (max-width: $md2+px) {
        font-size: 100px;
    }

    @media (max-width: $md3+px) {
        font-size: 50px;
    }

    @media (max-width: $md5+px) {
        font-size: 45px;
    }

    @media (max-width: $md6+px) {
        font-size: 40px;
    }
}

.font-64 {
    font-family: $caveat;
    font-size: 64px;
    line-height: 1.2;
    background: linear-gradient(92deg, #fab078 11.46%, #ffe5be 116.47%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: $md3+px) {
        font-size: 50px;
    }

    @media (max-width: $md5+px) {
        font-size: 45px;
    }

    @media (max-width: $md6+px) {
        font-size: 40px;
    }
}

.font-64-b {
    font-family: $caveat;
    font-size: 64px;
    line-height: 1.2;
    background: linear-gradient(92deg, #184a6d 11.46%, #3d6986 116.47%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: $md3+px) {
        font-size: 50px;
    }

    @media (max-width: $md5+px) {
        font-size: 45px;
    }

    @media (max-width: $md6+px) {
        font-size: 40px;
    }
}

.font-48 {
    font-family: $caveat;
    font-size: 48px;
    line-height: 1.2;
    background: linear-gradient(92deg, #184A6D 11.46%, #3D6986 116.47%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: $md3+px) {
        font-size: 42px;
    }

    @media (max-width: $md5+px) {
        font-size: 36px;
    }

    @media (max-width: $md6+px) {
        font-size: 32px;
    }
}

.font-30 {
    font-family: $montserrat;
    font-size: 30px;
    line-height: 1.2;
    color: #184A6D;

    @media (max-width: $md3+px) {
        font-size: 26px;
    }

    @media (max-width: $md5+px) {
        font-size: 24px;
    }

    @media (max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-24 {
    font-size: 24px;
    font-family: $montserrat;
    color: #fff;
    font-weight: 400;
    line-height: 1.5;

    @media (max-width: $md3+px) {
        font-size: 20px;
    }

    @media (max-width: $md5+px) {
        font-size: 18px;
    }

    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-20 {
    color: #184A6D;
    font-family: $montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;

    @media (max-width: $md3+px) {
        font-size: 18px;
    }

    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-20-b {
    color: rgba(0, 0, 0, 0.70);
    font-family: $montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @media (max-width: $md3+px) {
        font-size: 18px;
    }

    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-18 {
    color: rgba(0, 0, 0, 0.7);
    font-family: $montserrat;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;

    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-16 {
    font-family: $montserrat;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    line-height: 1.5;

    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-16-b {
    font-family: $montserrat;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    line-height: 1.5;

    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-14 {
    font-size: 12px;
    font-family: $montserrat;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    line-height: 1.5;
}

.font-12 {
    font-size: 12px;
    font-family: $montserrat;
    color: #184a6d;
    font-weight: 600;
    line-height: 1.5;
}

.react-tel-input {
    background-color: transparent !important;
    background: rgba(255, 255, 255, 0.10) !important;
    height: 50px !important;
    width: 100% !important;
    border: 1px solid rgba(255, 255, 255, 0.30) !important;
    border-radius: 50px !important;

    @media (max-width: $md1+px) {
        height: 45px !important;
    }

    .flag-dropdown {
        background: transparent !important;
        background-color: transparent !important;
        border: none !important;

        &.open {}

        .selected-flag {
            background: transparent !important;
            background-color: transparent !important;
            border: none !important;

            .flag {}

            &:hover,
            &.open {
                background: transparent !important;
            }

            &.open {
                background: transparent !important;
            }

            &.open {
                border: none;
            }

            .arrow {}
        }

        .selected-flag:hover {
            background-color: transparent !important;
        }

        .selected-flag:focus {
            background-color: transparent !important;
        }
    }

    .form-control {
        height: 100% !important;
        outline: none;
        width: 100% !important;
        border: none !important;
        background-color: transparent !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 150% !important;
        color: #fff !important;

        @media (max-width: $md6+px) {
            font-size: 14px !important;
        }

        &::placeholder {}
    }

    .country-list {
        .country:hover {}

        .country.highlight {}
    }
}