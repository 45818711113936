@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 35px;
        margin: 60px 0;

        @media (max-width: $md3+px) {
            gap: 20px;
        }

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            margin: 40px 0;
        }

        .card {
            border-radius: 20px;
            background: #F7F7F7;
            box-shadow: 0px 4px 20px 0px rgba(0, 35, 80, 0.12);
            backdrop-filter: blur(8px);
            padding: 20px 35px 40px;

            @media (max-width: $md4+px) {
                padding: 20px 35px;
            }

            @media (max-width: $md6+px) {
                padding: 20px;
            }

            .icon {
                width: 40px;

                @media (max-width: $md6+px) {
                    width: 32px;
                }
            }

            .subtitle {
                margin: 30px 0 8px;

                @media (max-width: $md6+px) {
                    margin: 20px 0 8px;
                }
            }

            .text {
                max-width: 300px;

                @media (max-width: $md4+px) {
                    max-width: 100%;
                }
            }
        }
    }

    .desc {
        max-width: 535px;
        margin: 0 auto;
        text-align: center;
        color: #184A6D;
        font-style: italic;
        font-weight: 500;
    }

    .btn {
        margin-top: 30px;
        text-align: center;
    }
}