@import "../../../styles/var";

.sub {
    padding: 140px 0 0 0;
    position: relative;
}

.subBody {}

.subRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 34px;

    @media (max-width: $md3+px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0;
    }
}

.subRowLeft {
    flex: 0 1 557px;

    @media (max-width: $md3+px) {
        margin-bottom: 80px;
        text-align: center;
    }

    @media (max-width: $md6+px) {
        text-align: left;
    }
}

.subRowLeftContent {}

.subRowLeftContentTitle {
    background: linear-gradient(92deg, #184a6d 11.46%, #3d6986 116.47%) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    margin-bottom: 30px;
}

.subRowLeftContentSubTitle {
    margin-bottom: 40px;
    color: rgba(0, 0, 0, 0.7) !important;
    max-width: 557px;

    @media (max-width: $md3+px) {
        max-width: 390px;
        margin: 0 auto 30px auto;
    }
}

.subRowLeftContentBtn {}

.subRowRight {
    flex: 0 1 592px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subRowRightColumn {
    border-radius: 20px;
    padding: 40px;

    box-shadow: 0px 4px 20px 0px rgba(0, 35, 80, 0.12);
    backdrop-filter: blur(8.420175552368164px);

    @media (max-width: $md5+px) {
        padding: 20px;
    }
}

.subRowRightColumnTitle {
    color: #184a6d !important;
    margin-bottom: 17px;
    font-weight: 500 !important;
}

.subRowRightColumnList {}

.subRowRightColumnListItem {
    color: #000 !important;
    margin-bottom: 12px;
    list-style-type: disc;
    position: relative;
    left: 20px;
    padding-right: 20px;
}

.subRowRightColumnText {
    color: #184a6d !important;
    margin-top: 28px;
}

.subImg1 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 642px;
    height: 665px;
    z-index: 2;
    transform: scale(0.8) translate(-100px, 0);

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        object-position: left bottom;
    }

    @media (max-width: $md3+px) {
        display: none;
    }
}

.subImg2 {
    position: absolute;
    left: -280px;
    bottom: 140px;
    width: 922px;
    height: 945px;
    transform: rotate(-10deg);

    img {
        transform: translate(-100px, 0) scale(0.9);
        object-fit: contain;
        width: 100%;
        height: 100%;

        object-position: left bottom;
    }

    @media (max-width: $md3+px) {
        display: none;
    }
}

.subPlanImg {
    display: none;
    transform: scale(0.8) translate(-100px, 0);

    @media (max-width: $md3+px) {
        display: block;
        margin-top: 20px;

        img {
            max-width: 100%;
        }
    }
}