@import "../../../styles/var";

.ourValues {
    position: relative;

    .sphere {
        position: absolute;
        z-index: 2;

        @media (max-width: $md3+px) {
            display: none;
        }

        &_01 {
            width: 55px;
            left: 400px;
            top: -10px;
        }

        &_02 {
            width: 28px;
            top: -80px;
            right: 140px;
            filter: blur(5px);
        }

        &_03 {
            width: 52px;
            top: -50px;
            right: 70px;
            filter: blur(2px);
        }
    }

    .title {
        position: relative;
        z-index: 10;
        text-align: center;
    }

    .cards {
        position: relative;
        z-index: 10;
        display: grid;
        grid-template-columns: repeat(4, 265px);
        justify-content: space-between;
        justify-items: center;
        gap: 40px;
        margin-top: 75px;

        @media (max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 80px 10px;
        }

        @media (max-width: $md4+px) {
            margin-top: 65px;
        }

        @media (max-width: 600px) {
            gap: 60px 40px;
        }

        @media (max-width: $md6+px) {
            margin-top: 50px;
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
        }

        .card {
            text-align: center;

            &Image {
                height: 120px;

                @media (max-width: $md6+px) {
                    height: 90px;
                }

                transition: all 0.3s linear;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                }
            }

            .subtitle {
                margin: 20px 0 12px;
                font-weight: 600;

                @media (max-width: $md6+px) {
                    margin: 15px 0 10px;
                }
            }

            &Text {
                margin: 0 auto 0 auto;
                max-width: 265px;
            }
        }
    }
}