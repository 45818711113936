@import "../../../styles/var";

.bannerWrap {
    position: relative;
    background-image: url("../../../assets/img/HomePage/Banner/bg.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    @media (max-width: $md3+px) {
        background-position: bottom right;
    }
    @media (max-width: $md6+px) {
        background-image: url("../../../assets/img/HomePage/Banner/bg_mob.webp");
    }
}

.banner {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    min-height: 700px;
    @media (max-width: $md3+px) {
        min-height: unset;
        height: auto;
        display: block;
        padding: 144px 0 65px 0;
    }
    @media (max-width: $md6+px) {
        padding: 92px 0 13px 0;
    }
    .content {
        position: relative;
        z-index: 10;
        @media (max-width: $md3+px) {
            text-align: center;
        }
        .title {
            font-family: $caveat;
            font-size: 120px;
            font-weight: 400;
            line-height: 1.4;
            background: linear-gradient(92deg, #fab078 11.46%, #ffe5be 116.47%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media (max-width: $md4+px) {
                font-size: 80px;
            }
            @media (max-width: $md6+px) {
                font-size: 55px;
            }
        }
        .subtitle {
            margin: -20px 0 38px 0;
            color: #fff;
            font-family: $montserrat;
            font-size: 30px;
            font-weight: 400;
            line-height: 150%;
            @media (max-width: $md3+px) {
                margin: 0 0 10px 0;
            }
            @media (max-width: $md4+px) {
                font-size: 25px;
            }
            @media (max-width: $md6+px) {
                margin: 15px 0 20px 0;
                font-size: 17px;
            }
        }
        .text {
            max-width: 525px;
            color: #fff;
            font-family: $montserrat;
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            @media (max-width: $md3+px) {
                margin: 0 auto;
            }
            @media (max-width: $md4+px) {
                font-size: 16px;
            }
            @media (max-width: $md6+px) {
                max-width: 338px;
                font-size: 14px;
            }
        }
        .btn {
            margin-top: 50px;
            @media (max-width: $md3+px) {
                margin-top: 40px;
            }
            @media (max-width: $md6+px) {
                margin-top: 50px;
            }
        }
    }
    .images {
        position: absolute;
        bottom: 67px;
        right: -100px;
        width: 695px;
        @media (max-width: $md2+px) {
            transform: scale(0.8);
            bottom: 10px;
            right: -60px;
        }
        @media (max-width: $md3+px) {
            position: relative;
            transform: none;
            bottom: unset;
            right: unset;
            display: flex;
            margin: 68px auto 0 auto;
            width: 433px;
        }
        @media (max-width: $md6+px) {
            margin-top: 50px;
            width: 245px;
        }
        .objectWrap {
            position: relative;
            z-index: 3;
            width: 100%;
            .object {
                width: 100%;
                transform: scale(0.9) translateX(40px) translateY(50px);
            }
        }
        .sphereWrap {
            position: absolute;
            &_01 {
                width: 50px;
                z-index: 2;
                top: 102px;
                left: 62px;
                // transform: rotate(48deg);
                filter: blur(2px);
                @media (max-width: $md3+px) {
                    width: 35px;
                    top: 79px;
                    left: 17px;
                }
                @media (max-width: $md6+px) {
                    width: 18px;
                    top: 36px;
                    left: 27px;
                }
                .sphere {
                    transform: translateX(-50px) translateY(110px);
                }
            }
            &_02 {
                width: 17px;
                z-index: 2;
                left: 109px;
                bottom: 172px;
                filter: blur(1px);
                @media (max-width: $md3+px) {
                    display: none;
                }
                .sphere {
                    transform: translateX(5px) translateY(10px);
                }
            }
            &_03 {
                width: 74px;
                z-index: 4;
                left: 133px;
                bottom: 162px;
                @media (max-width: $md3+px) {
                    bottom: 61px;
                    left: 59px;
                }
                @media (max-width: $md6+px) {
                    width: 41px;
                    bottom: 33px;
                    left: 40px;
                }
                .sphere {
                    transform: translateX(20px) translateY(50px) rotate(-20deg);
                }
            }
            &_04 {
                width: 32px;
                z-index: 2;
                bottom: 88px;
                right: 207px;
                filter: blur(2px);
                @media (max-width: $md3+px) {
                    display: none;
                }
                .sphere {
                    transform: rotate(72deg);
                }
            }
            &_05 {
                width: 52px;
                z-index: 4;
                top: 125px;
                right: 46px;
                @media (max-width: $md3+px) {
                    top: 79px;
                    right: -17px;
                    width: 31px;
                }
                @media (max-width: $md6+px) {
                    width: 19px;
                    top: 39px;
                    right: -1px;
                }
                .sphere {
                    transform: translateX(-50px) translateY(60px);
                }
            }
            &_06 {
                width: 40px;
                z-index: 2;
                top: -25px;
                right: 173px;
                filter: blur(2px);
                @media (max-width: $md3+px) {
                    top: 10px;
                    right: 106px;
                    width: 31px;
                }
                @media (max-width: $md6+px) {
                    width: 14px;
                    top: 4px;
                    right: 69px;
                }
                .sphere {
                    transform: translateX(30px) translateY(70px);
                }
            }
            .sphereContainer {
                width: 100%;
                .sphere {
                    width: 100%;
                }
            }
        }
    }
}
