@import "../../../styles/var";

.sub {
    position: relative;
}

.subBody {}

.subRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 34px;
    align-items: flex-start;

    @media (max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0;
        align-items: center;
    }
}

.subRow2 {
    margin-top: 80px;
    align-items: unset;

    @media (max-width: $md3+px) {
        margin-top: 20px;
        gap: 20px;
    }
}

.subRowLeft {
    flex: 0 1 557px;

    @media (max-width: $md3+px) {
        margin-bottom: 80px;
        text-align: center;
    }

    @media (max-width: $md6+px) {
        text-align: left;
    }
}

.subRowLeftContent {
    @media (max-width: $md3+px) {
        margin-bottom: 80px;
        text-align: center;
    }

    @media (max-width: $md6+px) {
        text-align: left;
    }
}

.subRowLeftContentTitle {
    background: linear-gradient(92deg, #184a6d 11.46%, #3d6986 116.47%) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    margin-bottom: 30px;
}

.subRowLeftContentSubTitle {
    margin-bottom: 40px;
    color: rgba(0, 0, 0, 0.7) !important;
    max-width: 557px;

    @media (max-width: $md3+px) {
        max-width: 390px;
        margin: 0 auto 30px auto;
    }
}

.subRowLeftContentBtn {}

.subRowRight {
    flex: 0 1 592px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subRowRightColumn {
    border-radius: 20px;
    padding: 40px;

    box-shadow: 0px 4px 20px 0px rgba(0, 35, 80, 0.12);
    backdrop-filter: blur(8.420175552368164px);

    @media (max-width: $md3+px) {
        width: 100%;
        max-width: 592px;
        margin: 0 auto;
    }

    @media (max-width: $md5+px) {
        padding: 20px;
    }
}

.subRowRightColumnTitle {
    color: #184a6d !important;
    margin-bottom: 17px;
    font-weight: 500 !important;
}

.subRowRightColumnList {}

.subRowRightColumnListItem {
    color: #000 !important;
    margin-bottom: 12px;
    list-style-type: disc;
    position: relative;
    left: 20px;
    padding-right: 20px;
}

.subRowRightColumnText {
    color: #184a6d !important;
    margin-top: 28px;
}

.desc {
    text-align: center;
    max-width: 535px;
    margin: 60px auto 0;
    color: #184A6D;
    font-family: $montserrat;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 150%;

    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}