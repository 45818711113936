@import "../../../styles/var";

.explore {
    position: relative;
    z-index: 3;
}

.exploreBody {}

.exploreRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}

.exploreRowLeft {
    flex: 0 1 640px;

    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-top: 50px;
        text-align: center;
        order: 2;
    }

    @media (max-width: $md6+px) {
        margin-top: 30px;
    }

    img {
        width: 100%;

        @media (max-width: $md3+px) {
            max-width: 640px;
            margin: 0 auto;
        }
    }
}

.exploreRowLeftImg {
    transform: translate(0, 200px);
}

.exploreRowRight {
    flex: 0 1 525px;

    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}

.exploreRowRightTitle {
    background: linear-gradient(92deg, #184a6d 11.46%, #3d6986 116.47%) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    margin-bottom: 40px;
}

.exploreRowRightText {
    color: rgba(0, 0, 0, 0.7) !important;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: $md6+px) {
        margin-bottom: 15px;
    }
}

.btn {
    margin-top: 50px;

    @media (max-width: $md3+px) {
        margin-top: 40px;
    }

    @media (max-width: $md6+px) {
        margin-top: 50px;
    }
}