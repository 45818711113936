@import "../../../styles/var";

.reviews {
    position: relative;

    .title {
        @media (max-width: $md6+px) {
            text-align: center;
            max-width: 270px;
            margin: 0 auto;
        }
    }

    .swiperWrap {
        .card {
            position: relative;
            height: 100%;
            padding: 40px 35px;

            border-radius: 20.048px;
            border-top: 1.203px solid rgba(255, 255, 255, 0.4);
            border-bottom: 1.203px solid rgba(255, 255, 255, 0.4);
            background: linear-gradient(161deg, #f7f7f7 0%, rgba(255, 255, 255, 0.63) 101.7%);
            box-shadow: 0px 4px 20px 0px rgba(0, 35, 80, 0.12);
            backdrop-filter: blur(8px);

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            @media (max-width: $md6+px) {
                padding: 25px 20px;
            }

            &Header {

                .cardIcon {
                    width: 60px;

                    @media (max-width: $md6+px) {
                        width: 50px;
                    }
                }

                &Content {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .cardTitle {
                        color: #184a6d !important;
                        font-weight: 500 !important;
                    }

                    .cardStars {
                        width: 94px;
                    }
                }
            }

            &Text {
                margin-top: 8px;
                color: #000 !important;
                font-weight: 300 !important;
            }
        }

        :global(.homeReviewsSwiper) {
            height: 100%;
            overflow: visible;
        }

        :global(.homeReviewsSwiperSlide) {
            height: auto !important;
            max-width: 345px;

            @media (max-width: 700px) {
                max-width: 100%;
            }
        }

        .navigation {
            margin: auto;
            display: flex;
            gap: 12px;

            @media (max-width: 750px) {
                position: relative;
                margin-top: 20px;
                justify-content: center;
                top: unset;
                right: unset;
            }

            .nav {
                width: 30px;
                position: relative;
                cursor: pointer;

                &_left {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.navigation {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    position: relative;
    z-index: 3;

    img {
        cursor: pointer;

        &:first-child {
            transform: rotate(180deg);
        }

        &:nth-child(2) {
            margin-left: 20px;
        }
    }
}

.managementTitle {
    margin-bottom: 60px;
    text-align: center;
    background: linear-gradient(92deg, #184a6d 11.46%, #3d6986 116.47%) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    position: relative;
    z-index: 2;

    @media (max-width: $md4+px) {
        margin-bottom: 40px;
    }
}

.managementImg {
    position: absolute;
    width: 800px;
    height: 800px;
    pointer-events: none;
    left: -160px;
    top: -170px;
    transform: translate(-300px, 0);

    @media (max-width: $md4+px) {
        width: 600px;
        height: 600px;
        left: -250px;
        top: -80px;
    }

    @media (max-width: $md6+px) {
        width: 500px;
        height: 500px;
        top: -20px;
        left: -200px;
    }

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}