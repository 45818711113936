@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 593px;
    gap: 40px;

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
    }

    .content {
        position: relative;
        z-index: 5;
    }

    .tabs {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .tab {
            cursor: pointer;
            border-radius: 10px;
            border: 1px solid rgba(255, 255, 255, 0.20);
            padding: 30px;
            border-radius: 20px;
            background: #F7F7F7;
            box-shadow: 0px 4px 20px 0px rgba(0, 35, 80, 0.12);

            @media (max-width: $md6+px) {
                padding: 15px 20px;
            }

            &.active {
                .arrow {
                    transform: rotate(90deg);
                }

                .desc {
                    display: block;
                }
            }

            .head {
                display: grid;
                align-items: center;
                gap: 20px;
                grid-template-columns: 1fr 30px;

                @media (max-width: $md6+px) {
                    grid-template-columns: 1fr 24px;
                }

                .subtitle {
                    font-weight: 400;
                }

                .arrow {
                    width: 100%;
                    transition: all .2s;
                }
            }

            .desc {
                display: none;
                margin-top: 15px;
                color: #245476;
                max-width: 506px;
                font-family: $montserrat;
                font-size: 16px;
                font-weight: 300;
                line-height: 150%;

                @media (max-width: $md6+px) {
                    font-size: 14px;
                }
            }
        }
    }
}