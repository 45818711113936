@import "../../styles/var";

.form {
    position: fixed;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 9999;
    background: rgba($color: #000, $alpha: 0.2);
    backdrop-filter: blur(7px);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-height: 740px) {
        align-items: unset;
    }

    @media (max-width: $md5+px) {
        align-items: unset;
    }

    &Active {
        opacity: 1;
        pointer-events: visible;

        .phoneIntContClass {
            border: 1px solid red !important;
        }
    }

    .closeZone {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100%;
    }

    .formContent {
        position: relative;
        z-index: 1010;
        width: 560px;
        border-radius: 40px;
        background: #0B2B4C;
        padding: 40px 40px 40px 40px;
        transition: all 0.3s;

        @media (max-width: $md5+px) {
            border-radius: 0;
        }

        .success {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1020;
            border-radius: 40px;
            opacity: 0;
            background-color: #0B2B4C;
            pointer-events: none;
            transition: all 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100px;

                @media (max-width: $md4+px) {
                    width: 100px;
                }
            }

            &Active {
                pointer-events: visible;
                opacity: 1;
            }
        }

        @media (max-height: 740px) {
            overflow: auto;
        }

        @media (max-width: $md5+px) {
            overflow: auto;
            overflow-x: hidden;
            width: 100%;
        }

        @media (max-width: 700px) {
            padding: 100px 18px 50px 18px;
        }

        .closeBtn {
            width: 24px;
            height: 24px;
            position: absolute;
            z-index: 9999;
            top: 20px;
            right: 20px;
            cursor: pointer;

            @media (max-height: 740px) {
                right: 10px;
                top: 10px;
            }

            @media (max-width: $md5+px) {
                right: 10px;
                top: 10px;
            }

            @media (max-width: 700px) {
                top: 60px;
                right: 20px;
            }

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 3px;
                background: #fff;
                top: calc(50% - 1px);
                left: 0;
                transform: rotate(45deg);
            }

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 3px;
                background: #fff;
                top: calc(50% - 1px);
                left: 0;
                transform: rotate(-45deg);
            }
        }

        .title {
            color: #FAB078;
            font-weight: 500;
        }

        .subtitle {
            font-weight: 400;
            margin: 20px 0 35px 0;
            color: #fff;
            max-width: 400px;

            @media (max-width: $md6+px) {
                margin: 20px 0 30px 0;
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .dd {
                height: 50px;
                border: 1px solid rgba(255, 255, 255, 0.30);
                border-radius: 50px;
                background: rgba(255, 255, 255, 0.10);

                @media (max-width: $md1+px) {
                    height: 45px;
                }

                :global(.Dropdown-root) {
                    width: 100%;
                    height: 100%;
                }

                :global(.Dropdown-control) {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center !important;
                    padding: 0 27px;
                    top: 0 !important;

                    @media (max-width: $md6+px) {
                        padding: 0 20px;
                    }
                }

                :global(.Dropdown-placeholder) {
                    color: rgba(255, 255, 255, 0.70);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    text-transform: capitalize;

                    @media (max-width: $md6+px) {
                        font-size: 14px;
                    }
                }

                :global(.is-selected) {}

                :global(.Dropdown-arrow) {
                    top: 50%;
                    transform: translateY(-50%);
                    right: 20px !important;
                }

                :global(.Dropdown-menu) {
                    font-weight: 500;
                    font-size: 16px;

                    @media (max-width: $md6+px) {
                        font-size: 14px !important;
                    }
                }

                &.incorrect {
                    border-color: red !important;
                }
            }

            .input {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #fff;
                border: 1px solid rgba(255, 255, 255, 0.30);
                background: rgba(255, 255, 255, 0.10);
                padding: 0 27px;
                height: 50px;
                border-radius: 50px;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.70);
                }

                &.incorrect {
                    border-color: red !important;
                }

                @media (max-width: $md1+px) {
                    height: 45px;
                }

                @media (max-width: $md6+px) {
                    font-size: 14px;
                    padding: 0 20px;
                }
            }

            .check {
                align-items: center;
                gap: 15px;
                display: grid;
                grid-template-columns: 16px 1fr;

                &.incorrect {
                    .checkText {
                        color: red !important;
                    }
                }

                &Box {
                    position: relative;
                    border: 1px solid #fff;
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                    transition: all 0.1s linear;

                    .checkMark {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        width: 14px;
                        opacity: 0;
                        transition: all .1s;
                    }

                    &Active {
                        .checkMark {
                            opacity: 1 !important;
                        }
                    }
                }

                &Text {
                    color: #fff;

                    a {
                        font-weight: 600;
                        text-decoration: underline;
                        color: #fff;

                        &:hover {
                            color: rgba($color: #fff, $alpha: 0.8);
                        }
                    }
                }
            }

            .btn {
                .submit {
                    position: relative;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    background: #ffe66d;
                    border-radius: 5px;
                    padding: 13px 30px;

                    font-weight: 800;
                    font-size: 19px;
                    line-height: 23px;
                    color: #29285e;
                    text-align: center;
                    cursor: pointer;
                    transition: 0.3s ease-in-out;

                    &:hover {
                        box-shadow:
                            0px 0px 0px 2.5px #fff,
                            0px 0px 0px 5px #29285e,
                            0px 0px 0px 10px white,
                            0px 0px 0px 10.5px #29285e;
                        background: white;
                        color: #29285e;
                    }

                    @media (max-width: $md6+px) {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
        }
    }
}