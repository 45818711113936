@import "../../../styles/var";

.footer {
    position: relative;
    z-index: 10;
    color: rgba(0, 0, 0, 0.7);
    display: grid;
    grid-template-columns: 100px auto 1fr;
    gap: 60px;
    padding-bottom: 30px;

    @media (max-width: $md1+px) {
        grid-template-columns: 100px auto 1fr;
    }

    @media (max-width: $md3+px) {
        grid-template-columns: 100px 1fr;
        gap: 30px 100px;

        .logo {
            grid-row-start: 1;
            grid-row-end: 1;
        }

        .info {
            grid-row-start: 1;
            grid-row-end: 1;
        }

        .disclaimer {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    @media (max-width: $md5+px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    a {
        color: rgba(0, 0, 0, 0.7);
        text-decoration: none;
        display: flex;

        &:hover {
            color: #000;
        }
    }

    .logo {
        width: 100%;

        img {
            height: 53px;
        }
    }

    .info {
        font-family: $montserrat;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.7);
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 60px;

        @media (max-width: 870px) {
            display: block !important;
        }

        @media (max-width: $md3+px) {
            justify-content: unset;
            flex-direction: unset;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        .contacts,
        .docs {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .contacts {
            display: block;

            @media (max-width: $md1+px) {}

            @media (max-width: 870px) {
                display: block !important;
            }
        }

        .docs {}
    }

    .contactsFirst,
    .contactsSecond {

        a,
        p {
            display: block;
            margin-bottom: 10px;
        }

        @media (max-width: 870px) {
            margin-bottom: 30px;
        }
    }

    .contactsFirst {}

    .contactsSecond {
        margin-top: 20px;

        a {}
    }

    .disclaimer {
        font-family: $montserrat;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;

        &Title {
            font-weight: 400;
            margin-bottom: 6px;
        }

        .rights {
            text-align: right;
            font-family: $montserrat;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin-top: 10px;

            @media (max-width: $md1+px) {
                margin-top: 30px;
            }

            @media (max-width: $md4+px) {
                margin-top: 20px;
                text-align: left;
            }
        }
    }
}